export type Project = {
  id: number;
  name: string;
  img: string;
  time: string;
  company: string;
  description: string;
  webURL?: string;
  gitURL?: string;
  backgroundColor: string;
  textColor: string;
};

const projects = [
  {
    id: '1',
    name: 'React App \nDevelopment ',
    img: 'images/apple-clone.png',
    time: 'with React, JavaScript, Express, NodeJs',
    company: 'Apple Music Clone',
    description:
      'Created a clone of the Apple Music web app with as close style and functionality as possible to the original.',
    webURL: 'https://whispering-thicket-41770.herokuapp.com/browse',
    gitURL: 'https://github.com/nhicung/apple-music-clone',
    backgroundColor: '#6F2232',
    textColor: '#fff',
  },
  {
    id: '2',
    name: 'React App \nDevelopment ',
    img: 'images/cung-design.gif',
    time: 'with React, JavaScript, MaterialUI',
    company: 'Art Collection',
    description:
      "Developed a single-page application to showcase a professional artist's artworks.",
    gitURL: 'https://github.com/nhicung/CungDesign',
    backgroundColor: '#1A1A1D',
    textColor: '#C3073F',
  },
  {
    id: '3',
    name: 'React App \nDevelopment ',
    img: 'images/my-read.png',
    time: 'with React, JavaScript, MaterialUI',
    company: 'My Reading Collection',
    description:
      'Implemented a single-page application to keep track of my favorite readings.',
    gitURL: 'https://github.com/nhicung/my-read',
    backgroundColor: '#6F2232',
    textColor: '#fff',
  },
];

export default projects;
