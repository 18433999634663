export type Project = {
  id: number;
  name: string;
  img: string;
  time: string;
  company: string;
  description: string;
  webURL?: string;
  gitURL?: string;
  backgroundColor: string;
  textColor: string;
};

const jobs = [
  {
    id: '1',
    name: 'Software Engineer',
    img: 'images/op.png',
    time: "Nov '23 - Now ",
    company: 'O Positiv',
    description:
      'Develop, test, and deploy new front-end features in TypeScript and React. Refactor the existing hard-coded templates into reusable and modularized components for higher maintainability and reusability.',
    webURL: 'https://opositiv.com/',
    backgroundColor: '#1A1A1D',
    textColor: '#C3073F',
  },
  {
    id: '2',
    name: 'Front End \nSoftware Engineer',
    img: 'images/reggie.png',
    time: "Jun '22 - Sep '23 ",
    company: 'Reggie Pet, Inc.',
    description:
      'Managed technical process from ideation, mockup design and development for the company’s D2C e-commerce web application, utilizing React and JavaScript.',
    webURL: 'https://reggie.com/',
    backgroundColor: '#6F2232',
    textColor: '#fff',
  },
  {
    id: '3',
    name: 'Front End \nReact Engineer',
    img: 'images/jamfeed.png',
    time: "Jun '21 - Jun '22 ",
    company: 'JamFeed',
    description:
      'Created a Content Management System that serves as a no-code website builder for musicians to build their portfolios, using React and MaterialUI.',
    webURL: 'https://www.jamfeed.com/',
    backgroundColor: '#1A1A1D',
    textColor: '#C3073F',
  },
];

export default jobs;
